import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";

// Utilities
import kebabCase from "lodash/kebabCase";

const BlogSidebar = (data) => {
  const { t } = useTranslation();
  const blogs = useStaticQuery(graphql`
    query {
      projectsDE: allMarkdownRemark(
        filter: {
          frontmatter: { language: { eq: "de" } }
          fileAbsolutePath: { regex: "/(/markdown-pages/)/" }
        }
        sort: { order: DESC, fields: frontmatter___date }
        limit: 3
      ) {
        edges {
          node {
            frontmatter {
              title
              date
              slug
              images {
                mainImage {
                  childImageSharp {
                    gatsbyImageData(width: 800, layout: CONSTRAINED)
                  }
                }
                galleryImages {
                  childImageSharp {
                    gatsbyImageData(width: 800, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
        }
      }
      projectsFR: allMarkdownRemark(
        filter: {
          frontmatter: { language: { eq: "fr" } }
          fileAbsolutePath: { regex: "/(/markdown-pages/)/" }
        }
        sort: { order: DESC, fields: frontmatter___date }
        limit: 3
      ) {
        edges {
          node {
            frontmatter {
              title
              date
              slug
              images {
                mainImage {
                  childImageSharp {
                    gatsbyImageData(width: 800, layout: CONSTRAINED)
                  }
                }
                galleryImages {
                  childImageSharp {
                    gatsbyImageData(width: 800, layout: CONSTRAINED)
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const projects =
    data.frontmatter.language === "fr" ? blogs.projectsFR : blogs.projectsDE;

  return (
    <div className="widget-area">
      {/* <div className="widget widget_search">
        <h3 className="widget-title">Search</h3>

        <form className="search-form">
          <label>
            <input
              type="search"
              className="search-field"
              placeholder="Search..."
            />
          </label>
          <button type="submit">
            <i className="bx bx-search-alt"></i>
          </button>
        </form>
      </div> */}

      <div className="widget widget_tracer_posts_thumb">
        <h4 className="widget-title">{t("Projekt Info")}</h4>

        <article className="item">
          <p>
            {t("PV-Anlage")}: {data.frontmatter.kWp}kWp
          </p>
          <p>
            {t("Nutzungseinheiten Wohnungen")}: {data.frontmatter.neW}
          </p>
          <p>
            {t("Nutzungseinheiten Gewerbe")}: {data.frontmatter.neG}
          </p>
          <p>
            {t("Inbetriebnahme")}: {data.frontmatter.date}
          </p>
          <p>
            {t("Kunde")}: {data.frontmatter.kunde}
          </p>
          <p>
            {t("Ort")}: {data.frontmatter.ort}
          </p>
        </article>
      </div>
      <div className="widget widget_tag_cloud">
        <h3 className="widget-title">{t("Tags")}</h3>

        <div className="tagcloud">
          {data.frontmatter.tags.map((tag) => {
            return (
              <Link key={Math.random()} to={`/projects/${kebabCase(tag)}/`}>
                {tag} <span className="post-count"></span>
              </Link>
            );
          })}
        </div>
      </div>

      <div className="widget widget_tracer_posts_thumb">
        <h3 className="widget-title">{t("Aktuelle Projekte")}</h3>
        {projects.edges.map((blog) => (
          <article className="item" key={Math.random()}>
            <Link to={blog.node.frontmatter.slug} className="thumb">
              <GatsbyImage
                alt="Project"
                image={
                  blog.node.frontmatter.images.mainImage.childImageSharp
                    .gatsbyImageData
                }
              />
            </Link>
            <div className="info">
              <span>{blog.node.frontmatter.date}</span>
              <h4 className="title usmall">
                <Link to={blog.node.frontmatter.slug}>
                  {blog.node.frontmatter.title}
                </Link>
              </h4>
            </div>

            <div className="clear"></div>
          </article>
        ))}
      </div>
    </div>
  );
};

export default BlogSidebar;
