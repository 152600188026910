import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import PageBanner from "../components/Common/PageBanner";
import Footer from "../components/App/Footer";
import { GatsbyImage } from "gatsby-plugin-image";
import { useTranslation } from "gatsby-plugin-react-i18next";
import BlogSidebar from "../components/BlogContent/BlogSidebar";
import BlogSidebarEMob from "../components/BlogContent/BlogSidebarEMob";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Seo } from "../components/App/SEO";

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { t } = useTranslation();
  const { Project } = data;
  const { frontmatter, html } = Project;
  let post = data.Project;
  const settings = {
    dots: true,
    infinite: true,
    autoplay: true,
  };

  //Das Query nimmt teilweise falsche md-files. Mit diesem if-statement wird dies gefixed
  if (frontmatter.typ != null) {
    let galleryImages = post.frontmatter.images.galleryImages;
    return (
      <Layout>
        <Navbar />
        <PageBanner
          pageTitle={t("Projekte")}
          homePageText="Home"
          homePageUrl="/"
          activePageText={t("Projekte")}
        />
        <section className="blog-details-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <div className="blog-details-desc">
                  <h1 className="sub-title">{frontmatter.title}</h1>
                  <h2>{frontmatter.date}</h2>
                  <div className="article-image">
                    <Slider {...settings} className="overflow-hidden">
                      {galleryImages.map((image) => (
                        <GatsbyImage
                          key={Math.random()}
                          alt="ProjectImage"
                          image={image.childImageSharp.gatsbyImageData}
                        />
                      ))}
                    </Slider>
                  </div>
                  <br />
                  <div
                    className="blog-post-content"
                    dangerouslySetInnerHTML={{ __html: html }}
                  />
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                {frontmatter.typ === "ZEV" ? (
                  <BlogSidebar frontmatter={frontmatter} />
                ) : (
                  <BlogSidebarEMob frontmatter={frontmatter} />
                )}
              </div>
            </div>
          </div>
        </section>
        <Footer />
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query ($id: String!, $language: String!) {
    Project: markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        Garagen
        Ladestationen
        date(locale: $language, formatString: "MMMM DD, YYYY")
        slug
        title
        kWp
        neW
        neG
        kunde
        ort
        tags
        language
        priority
        typ
        images {
          mainImage {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
          galleryImages {
            childImageSharp {
              gatsbyImageData(width: 800, layout: CONSTRAINED)
            }
          }
        }
      }
    }
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export const Head = () => (
  <Seo
    title="Auswahl unserer ZEV und Elektromobilität Projekte"
    description="Auswahl von laufenden Zusammenschluss zum Eigenverbrauch und Elektromobilitätsprojekte in welcher Blockstrom Strom, Wasser und Wärme abrechnet"
  />
);
